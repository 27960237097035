import router from '@/router';
import { AZURE_LOGIN_ROUTE } from '@/router/routes';
import useConfigs from '@/util/configs';
import useAuthApi from '@/api/auth';
import useGoogleAnalytics, { GA_EVENTS } from '@/components/composables/useGoogleAnalytics';
import useLocale from '@/components/composables/useLocale';

const {
  login,
  getUserInfo,
  user,
} = useAuthApi();
const { selectedLocale } = useLocale();
const { environment } = useConfigs();

router.beforeEach(async (to) => {
  const token = window.location.href.split('#id_token=')[1];

  if (token && 0 < token.length) {
    login(token as string);
    return;
  }

  if (false === to.meta.hasFooter) {
    document.body.classList.add('pb-0');
  }

  if (to.meta.requiresAuth) {
    await getUserInfo().catch(() => {
      window.location.replace(AZURE_LOGIN_ROUTE);
    }).finally(() => {
      if (!user.value?.voucherCode) {
        window.location.replace(AZURE_LOGIN_ROUTE);
      }
    });
  }
});

router.afterEach((to) => {
  useGoogleAnalytics().trackEvent(
    GA_EVENTS.PAGE_VIEW,
    {
      environment: environment.value,
      area: 'website',
      user_status: user.value?.voucherCode ? 'logged in' : 'logged out',
      virtual_page_location: window.location.href,
      virtual_page_title: `${to.meta.fullName as string}`,
      content_group: 'service',
      language: `${selectedLocale.value?.title}`,
      country: environment.value,
    },
    to.meta.fullName as string,
  );
});

export default router;
