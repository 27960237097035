/* eslint-disable no-shadow */
import { computed, readonly } from 'vue';
import { ARAG_DOMAINS } from '@/util/configs';
import { domain } from '@/util/domainUtils';

// AKS
import aksDemo from './aks/demo.json';
import aksDev from './aks/dev.json';
import aksTest from './aks/test.json';
// SE
import akademikerforsakring from './se/akademikerforsakring.json';
import seDev from './se/dev.json';
import seProd from './se/prod.json';
import raksystems from './se/raksystems.json';
import digitaltjanstforjuridiskadokument from './se/digitaltjanstforjuridiskadokument.json';
import sustera from './se/sustera.json';
// UK
import ukDev from './uk/dev.json';
import documentcentre from './uk/documentcentre.json';
import ukProd from './uk/prod.json';
// IE
import ieProd from './ie/prod.json';
import itProd from './it/prod.json';
// AU
import auProd from './au/prod.json';
import auProdMain from './au/prod-main.json';
// SL
import slProd from './sl/prod.json';

export default function useConfiguration() {
  const configuration = computed(() => {
    switch (domain) {
      case ARAG_DOMAINS.AKS_DEMO:
        return aksDemo;
      case ARAG_DOMAINS.AKS_DEV:
        return aksDev;
      case ARAG_DOMAINS.AKS_TEST:
        return aksTest;
      case ARAG_DOMAINS.SE_AKADEMIKERFORSAKRING:
        return akademikerforsakring;
      case ARAG_DOMAINS.SE_DEV:
        return seDev;
      case ARAG_DOMAINS.SE_PROD:
        return seProd;
      case ARAG_DOMAINS.SE_RAKSYSTEMS:
        return raksystems;
      case ARAG_DOMAINS.SE_DIGITALTJANSTFORJURIDISKADOKUMENT:
        return digitaltjanstforjuridiskadokument;
      case ARAG_DOMAINS.SE_SUSTERA:
        return sustera;
      case ARAG_DOMAINS.UK_DEV:
        return ukDev;
      case ARAG_DOMAINS.UK_DOCUMENTCENTER:
        return documentcentre;
      case ARAG_DOMAINS.UK_PROD:
        return ukProd;
      case ARAG_DOMAINS.IE_PROD:
      case ARAG_DOMAINS.IE_DOCUMENTCENTER:
        return ieProd;
      case ARAG_DOMAINS.IT_PROD:
        return itProd;
      case ARAG_DOMAINS.AU_PROD_NEW:
      case ARAG_DOMAINS.AU_PROD_MAIN:
        return auProdMain;
      case ARAG_DOMAINS.AU_PROD:
        return auProd;
      case ARAG_DOMAINS.SL_PROD:
        return slProd;
      default:
        return aksDev;
    }
  });

  return {
    ...readonly(configuration.value),
  };
}
