import { computed, Ref, ref } from 'vue';
import { User } from '@/types';
import useAxios from '@/util/axios';
import {
  ROUTE_PATH_EXPLORE,
  API_ROUTE_INTROSPECTION,
} from '@/router/routes';
import { getCookie, setCookie } from '@/util/cookies';
import useLocale from '@/components/composables/useLocale';

const user = ref(null) as Ref<User | null>;

export default function useAuthApi() {
  const { api } = useAxios();

  const { onUpdate } = useLocale();

  const setAuthorizationToken = (token: string) => {
    setCookie('authorization_token', token);
  };

  const resetAuthorizationToken = () => {
    setCookie('authorization_token', '');
  };

  const isLoggedIn = computed(() => !!getCookie('authorization_token'));
  const isAdminUser = computed(() => !!user.value && -1 < user.value.roles.indexOf('ROLE_ADMIN'));

  const getUserInfo = async () => {
    const response = await api.get(
      API_ROUTE_INTROSPECTION,
    ).then((res) => {
      user.value = {
        email: res.data.email,
        roles: res.data.roles,
        locale: res.data.locale,
        voucherCode: res.data.vouchercode,
        branch: res.data.branch,
      };

      onUpdate(res.data.locale);
    });

    return response;
  };

  const login = async (authCode: string) => {
    await setAuthorizationToken(authCode);
    window.location.replace(ROUTE_PATH_EXPLORE);
  };

  const logout = async () => {
    await resetAuthorizationToken();
    user.value = null;
    window.location.reload();
  };

  const updateLocale = async (locale: string) => {
    onUpdate(locale);

    if (!isLoggedIn.value) {
      return null;
    }

    const response = await api.patch(API_ROUTE_INTROSPECTION, { locale });
    return response;
  };

  return {
    isLoggedIn,
    isAdminUser,
    user,
    getUserInfo,
    login,
    logout,
    updateLocale,
  };
}
