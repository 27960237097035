import useConfigs from '@/util/configs';

// VIEWs ROUTES
export const ROUTE_PATH_EXPLORE = '/';
export const ROUTE_PATH_MY_DOCS = '/my-docs';
export const ROUTE_PATH_MIGRATED_DOCS = '/migrated-docs';
export const ROUTE_PATH_QUESTIONNAIRE = '/questionnaire';
export const ROUTE_PATH_DISCLAIMER = '/disclaimer';
export const ROUTE_PATH_PRIVACY_POLICY = '/privacy-policy';
export const ROUTE_PATH_TERMS_AND_CONDITIONS = '/terms-and-conditions';

export const ROUTE_NAME_EXPLORE = 'Explore';
export const ROUTE_NAME_MY_DOCS = 'MyDocs';
export const ROUTE_NAME_MIGRATED_DOCS = 'MigratedDocs';
export const ROUTE_NAME_QUESTIONNAIRE = 'Questionnaire';
export const ROUTE_NAME_DISCLAIMER = 'Disclaimer';
export const ROUTE_NAME_PRIVACY_POLICY = 'PrivacyPolicy';
export const ROUTE_NAME_TERMS_AND_CONDITIONS = 'TermsAndConditions';

export const ROUTE_TITLE_EXPLORE = 'Explore Page';
export const ROUTE_TITLE_MY_DOCS = 'My Docs Page';
export const ROUTE_TITLE_MIGRATED_DOCS = 'My Migrated Docs Page';
export const ROUTE_TITLE_QUESTIONNAIRE = 'Questionnaire Page';
export const ROUTE_TITLE_DISCLAIMER = 'Disclaimer Page';
export const ROUTE_TITLE_PRIVACY_POLICY = 'Privacy Policy Page';
export const ROUTE_TITLE_TERMS_AND_CONDITIONS = 'Terms and Conditions Page';

export const GET_ROUTE_PATH_QUESTIONNAIRE = (id: string): string => `questionnaire?record_id=${id}`;
export const GET_ROUTE_PATH_QUESTIONNAIRE_CLIPBOARD_DOCUMENT = (id: string): string => `${window.location.origin}/questionnaire?document_id=${id}`;

// APIs ROUTES
export const API_ROUTE_DOCUMENTS = '/public/documents';
export const API_ROUTE_RECORDS = '/public/records';
export const API_ROUTE_RECORDS_MIGRATED = '/public/records/migrated';
export const API_ROUTE_DOCUMENTS_SEARCH = `${API_ROUTE_DOCUMENTS}/search`;
export const API_ROUTE_RECORDS_SEARCH = `${API_ROUTE_RECORDS}/search`;
export const API_ROUTE_RECORDS_SEARCH_MIGRATED = `${API_ROUTE_RECORDS_MIGRATED}/search`;
export const API_ROUTE_LEGAL_FIELDS = '/public/legal_fields';
export const API_ROUTE_INTROSPECTION = '/public/me';
export const API_ROUTE_BRANDING_IMAGES = '/branding/images';
export const API_ROUTE_BRANDING_STYLING = '/branding/styles';
export const API_ROUTE_BRANDING_CONFIGURATION = '/branding/configuration';
export const API_ROUTE_BRANDING_TOOLS = '/public/branding';

export const GET_API_ROUTE_DOCUMENT = (id: string): string => `/documents/${id}`;
export const GET_API_ROUTE_PUBLIC_DOCUMENT = (id: string): string => `${API_ROUTE_DOCUMENTS}/${id}`;
export const GET_API_ROUTE_DOCUMENT_PREVIEW = (id: string): string => `${GET_API_ROUTE_PUBLIC_DOCUMENT(id)}/preview`;
export const GET_API_ROUTE_DOCUMENT_DOWNLOAD = (id: string): string => `${GET_API_ROUTE_PUBLIC_DOCUMENT(id)}/download`;
export const GET_API_ROUTE_QUESTIONNAIRE = (id: string): string => `${GET_API_ROUTE_PUBLIC_DOCUMENT(id)}/questionnaire`;

export const GET_API_ROUTE_RECORD = (id: string): string => `${API_ROUTE_RECORDS}/${id}`;
export const GET_API_ROUTE_RECORD_PREVIEW = (id: string): string => `${GET_API_ROUTE_RECORD(id)}/preview`;
export const GET_API_ROUTE_RECORD_DOWNLOAD = (id: string): string => `${GET_API_ROUTE_RECORD(id)}/download`;
export const GET_API_ROUTE_RECORD_REPRESENTATION = (id: string): string => `${GET_API_ROUTE_RECORD(id)}/representation`;
export const GET_API_ROUTE_DOCUMENT_REPRESENTATION = (id: string): string => `/documents/${id}/representation`;

export const GET_API_ROUTE_RECORD_MIGRATED = (id: string): string => `${API_ROUTE_RECORDS_MIGRATED}/${id}`;
export const GET_API_ROUTE_RECORD_MIGRATED_PREVIEW = (id: string): string => `${GET_API_ROUTE_RECORD_MIGRATED(id)}/preview`;
export const GET_API_ROUTE_RECORD_MIGRATED_DOWNLOAD = (id: string): string => `${GET_API_ROUTE_RECORD_MIGRATED(id)}/download`;

const {
  BASE_URL,
  POLICY_ID,
  CLIENT_ID,
  NONCE,
  SCOPE,
  RESPONSE,
  PROMPT,
} = useConfigs().AZURE_LOGIN_PAGE;
export const AZURE_LOGIN_ROUTE = `${BASE_URL}?p=${POLICY_ID}&client_id=${CLIENT_ID}&nonce=${NONCE}&scope=${SCOPE}&response_type=${RESPONSE}&prompt=${PROMPT}&redirect_uri=${window.location.origin}`;
