import { createApp } from 'vue';
import { createGtm } from '@gtm-support/vue-gtm';
import useBrandingToolsApi from '@/api/brandingTools';
import useLocale from '@/components/composables/useLocale';
import useGoogleAnalytics from '@/components/composables/useGoogleAnalytics';
import router from '@/util/routerUtil';
import useConfigs from '@/util/configs';
import App from './App.vue';

const { load, translations, generalConfigurations } = useBrandingToolsApi();
const { GTM_CONTAINER_ID, USER_CENTRICS_ID } = useConfigs();
const { isTrackingEnabled, isDebuggingEnabled } = useGoogleAnalytics();
const { i18n, initLocales } = useLocale();

// Loading user centric
const userCentricScript = document.createElement('script');
userCentricScript.id = 'usercentrics-cmp';
userCentricScript.type = 'text/javascript';
userCentricScript.src = 'https://app.usercentrics.eu/browser-ui/latest/loader.js';
userCentricScript.async = true;
userCentricScript.setAttribute('data-settings-id', USER_CENTRICS_ID);
document.head.appendChild(userCentricScript);

// Loading webchat
const webChatScript = document.createElement('script');
webChatScript.id = 'webchatBundle';
webChatScript.type = 'text/javascript';
webChatScript.src = useConfigs().COGNIGY_WEBCHAT_BUNDLE;
webChatScript.defer = true;
document.body.appendChild(webChatScript);

// Loading branding configurations and then mount the app
load().finally(() => {
  // Initializing the locales configurations
  initLocales(
    translations.value.locales,
    translations.value.defaultLocale,
    translations.value.messages,
  );
  // Initializing the app title that appears on the window tab
  document.title = generalConfigurations.value.appTitle;
  // Mounting the app
  createApp(App)
    .use(
      createGtm({
        id: GTM_CONTAINER_ID,
        enabled: isTrackingEnabled.value,
        debug: isDebuggingEnabled.value,
      }),
    )
    .use(router)
    .use(i18n)
    .mount('#app');

  // Reloading user centric
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  uc.reloadOnOptOut(USER_CENTRICS_ID);
});
