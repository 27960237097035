
import { defineComponent } from 'vue';

import DcSpinner from '@/components/_shared/DcSpinner/DcSpinner.vue';

export default defineComponent({
  name: 'DcLoaderPane',
  components: {
    DcSpinner,
  },
});
