import { computed } from 'vue';
import { useRoute } from 'vue-router';
import { useGtm } from '@gtm-support/vue-gtm';
import useAuthApi from '@/api/auth';
import useConfigs from '@/util/configs';

const { environment } = useConfigs();

// eslint-disable-next-line no-shadow
export enum GA_EVENTS {
  SELECT_CONTENT = 'select_content',
  SEARCH = 'search',
  FILE_DOWNLOAD = 'file_download',
  FILE_VIEW = 'file_view',
  PERSONALISE = 'personalise',
  RESUME = 'resume',
  EDIT = 'edit',
  SHARE = 'share',
  ERROR = 'error',
  POPUP_VIEW = 'popup_view',
  PAGE_VIEW = 'page_view',
  REQUEST = 'request',
}

export default function useGoogleAnalytics() {
  const route = useRoute();

  const isTrackingEnabled = computed(() => 'localhost' !== environment.value);
  const isDebuggingEnabled = computed(() => 'localhost' === environment.value);

  const gtm = useGtm();
  const { user } = useAuthApi();

  const trackEvent = (
    event: GA_EVENTS,
    specialAttributes: Record<string, string> | null = null,
    pageFullName = '',
  ) => {
    const eventLocation = route?.meta?.fullName || pageFullName;
    if (!gtm || !eventLocation) return;
    gtm.trackEvent({
      event,
      event_location: eventLocation,
      access_code: user.value?.voucherCode,
      mandant: user.value?.branch,
      ...specialAttributes,
    });
  };

  return {
    isTrackingEnabled,
    isDebuggingEnabled,
    trackEvent,
  };
}
